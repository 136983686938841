*, *::before, *::after {
  box-sizing: border-box;
}

.oldImg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 10px;
  background-color: #f8f9fa;
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  overflow-x: auto;
}

.searchInput {
  width: 50%;
  padding: 12px 20px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.tableContainer {
  width: 100%;
  overflow-x: auto;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.table {
  width: 100%;
  max-width: 1000px;
  border-collapse: collapse;
  margin: 0 auto 20px auto; /* Center the table */
}

.table th,
.table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
  word-wrap: break-word;
}

.table th {
  background-color: #007bff;
  color: #ffffff;
}

.textRow:hover {
  background-color: #f1f1f1;
}

.deleteButton {
  width: 25%;
  background-color: #dc3545;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.deleteButton:disabled {
  background-color: #e0aeb3;
  cursor: not-allowed;
}

.deleteButton:hover:not(:disabled) {
  background-color: #c82333;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pageButton {
  background-color: #f1f1f1;
  border: none;
  padding: 8px 12px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 3px;
}

.activePage {
  background-color: #007bff;
  color: #fff;
}

.pageButton:hover:not(.activePage) {
  background-color: #ddd;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
}

.modalButtons {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.confirmButton {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.cancelButton {
  background-color: #6c757d;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.confirmButton:hover {
  background-color: #c82333;
}

.cancelButton:hover {
  background-color: #5a6268;
}

.successMessage,
.errorMessage {
  position: relative;
  padding: 15px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.successMessage {
  background-color: #d4edda;
  color: #155724;
}

.errorMessage {
  background-color: #f8d7da;
  color: #721c24;
}

.closeMessage {
  position: absolute;
  top: 5px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
  color: inherit;
}

.viewButton {
  background-color: #17a2b8;
  color: #fff;
  border: none;
  padding: 6px 12px;
  border-radius: 3px;
  cursor: pointer;
}

.viewButton:hover {
  background-color: #138496;
}

/* Flex container for search input and delete button */
.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .table th,
  .table td {
    padding: 10px;
    font-size: 14px;
  }

  .deleteButton,
  .viewButton {
    width: 100%;
    padding: 10px;
    font-size: 14px;
  }

  .modal {
    width: 90%;
  }

  .pagination {
    flex-wrap: wrap;
  }

  .pageButton {
    margin: 5px 2px;
  }

  /* Adjust sidebar for smaller screens if necessary */
  .sidebar {
    flex: 0 0 150px; /* Reduced width for smaller screens */
    padding: 10px; /* Reduced padding */
  }
}

/* Screen Reader Only */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* Styles */

.container {
  background-image: url('../../../images/pexels-ksenia-chernaya-3952073.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* Ensure the container is positioned relative */
  z-index: 1; /* Ensure the container is above the background */
}

.titleText {
  color: white;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 2rem;
  text-align: center;
}