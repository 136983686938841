/* Logout Button Styles */
.logoutButton {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 100%;
    text-align: left;
    padding-left: 20px;
  }
  
  .logoutButton button {
    background-color: #04AA6D;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .logoutButton button:hover {
    background-color: #09a86e;
  }
  