.title-text {
    color: white;
    font-weight: bold;
    margin-left: 40px;
  }
  
  input[type=text], select {
    width: 25%;
    padding: 12px 20px;
    margin: 8px 0;
    margin-left: 40px;
    display: block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  input[type=email], select {
    width: 25%;
    padding: 12px 20px;
    margin: 8px 0;
    margin-left: 40px;
    display: block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

.contact-textarea {
    width: 25%;
    padding: 12px 20px;
    margin: 8px 0;
    margin-left: 40px;
    display: block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
/* Style the submit button */
.button[type=submit] {
  width: 25%;
  background-color: #04AA6D;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 40px;
}
  
/* Add a background color to the submit button on mouse-over */
button[type=submit]:hover {
  background-color: #45a049;
}

.form-text {
  color: white;
  font-weight: bold;
  margin-left: 40px;
}

.img-background {
    background-image: url('../../../images/books_reading.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
}

label.form-text {
  color: white;
  margin-left: 40px;
  display: block;
  align-items: center;
}

input[type="checkbox"] {
  color: white;
  font-weight: bold;
  margin-right: 8px; 
}