/* Styles */

.site-img-background {
    background-image: url('../../images/books_lights.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
} 

.siteInfo p {
    font-size: 1.2em;
    margin-bottom: 15px;
    margin-left: 30px;
    color: white;
    font-weight: bold;
  }
  
  .siteInfo ul {
    list-style-type: disc;
    margin-left: 60px;
    color: white;
    font-weight: bold;
  }
  
  .siteInfo li {
    font-size: 1.1em;
    margin-bottom: 60px;
    margin-left: 30px;
    color: white;
    font-weight: bold;
  }
  
  .siteInfo a {
    color: #0077B5;
    text-decoration: none;
    font-weight: bold;
  }
  
  .siteInfo a:hover {
    text-decoration: underline;
  }