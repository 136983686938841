.readerImg {
    background-image: url('../../../images/library_reader.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
} 

.titleText {
    color: white;
    font-weight: bold;
    margin-left: 40px;
  }
  