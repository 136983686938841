.list-group {
  margin-top: 30px;
  border-radius: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.list-group-item {
  font-size: 1.2rem;
  border-radius: 0;
  border: none;
}

.list-group-item:hover {
  background-color: #f2f2f2;
}

.bookHeader h1 {
  margin-top: 0;
}

/* Custom styles for the table */
.tableContainer {
  margin: 20px auto;
  max-width: 800px;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.table th,
.table td {
  padding: 12px 15px;
  text-align: left;
  vertical-align: middle;
}

.table thead th {
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
}

.table tbody tr:nth-of-type(even) {
  background-color: #f7f7f7;
}