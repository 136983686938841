/* Sidebar Styles */

.sidebar {
  flex: 0 0 200px;
  background-color: #007bff; /* Change background color */
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: white;
  border: 1px solid #ccc; /* Add a border */
}

/* Media query for screens with a maximum width of 768px (mobile) */
@media (max-width: 768px) {
  .sidebar {
    flex: 1;
    width: 100%;
    padding: 10px;
  }
}

.sidebar h2 {
  margin-top: 0;
  font-size: 24px;
  text-transform: uppercase;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sidebar li:hover {
  background-color: #0056b3; /* Darker color on hover */
  border-radius: 5px;
}

/* Submenu Styles */

.submenu {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  background-color: #0056b3; /* Dark background color matching the main sidebar */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Slightly stronger shadow */
  border-radius: 5px; /* Rounded corners for submenu */
}

.submenu li {
  padding: 10px;
  margin-bottom: 5px;
}

.expandable {
  cursor: pointer;
}

.expandable:hover + .submenu,
.submenu:hover {
  display: block;
}

.submenu ul {
  display: none;
}

.submenu:hover ul {
  display: block;
  position: absolute;
  top: 0;
  left: 100%;
  background-color: #444; /* Dark background color */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Slightly stronger shadow */
  padding: 10px;
  border-radius: 5px; /* Rounded corners for submenu */
}

/* Link Styles */

a {
  text-decoration: none;
  color: #fff; /* Text color for links */
}

a:hover {
  text-decoration: underline;
}

/* Other Styles */

.menu {
  list-style: none;
}

.sidebar title-text {
  font-weight: bold; /* Make the text bold */
  text-transform: none; /* Prevent uppercase transformation */
}


/* ////
 */

 .sidebar h2 {
  font-family: 'YourChosenFont', sans-serif;
  font-size: 24px;
  font-weight: bold;
  background-color: #007bff;
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.sidebar h2:hover h2 {
  background-color: #0056b3;
}