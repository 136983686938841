.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.li {
  list-style: none;
  background-color: #f7f7f7;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  min-height: 100vh;
}

.booklist {
  flex: 1;
}

/* Toggle Button Styles */

/* Style for the toggle button */
.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #007bff; /* Change background color */
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 40px; /* Adjust the top position */
  right: 20px; /* Adjust the right position */
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.hamburger:hover {
  background-color: #0056b3; /* Darker color on hover */
}