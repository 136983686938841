/* Styles */

.oldImg {
    /* background-image: url('../../../images/old-books.jpg'); */
    background-image: url('../../../images/pexels-ksenia-chernaya-3952073.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
} 

.titleText {
    color: white;
    font-weight: bold;
    margin-left: 40px;
}

.textRow {
    color: white;
    margin-left: 20px;
}

button[type=submit] {
    width: 25%;
    background-color: #04AA6D;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 40px;
  }