.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

/* Login box */
.loginBox {
  background-color: rgba(255, 255, 255, 0.7); /* Transparent white background */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

/* Login box heading */
.loginBox h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Input container */
.inputContainer {
  margin-bottom: 20px;
  display: flex; /* Add this line to make input and label side by side */
  align-items: center; /* Add this line to vertically align input and label */
}

/* .inputContainer label {
  display: block;
  font-weight: bold;
  width: 100px; 
  text-align: right; 
  margin-right: 10px; 
} */

/* Input fields */
.inputContainer input[type=text], input[type=password] {
  flex: 1; /* Add this line to make the input expand and take available space */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  width: 100%; /* Set a fixed width for the input elements */
  font-size: 16px; /* Adjust the font size for placeholders */
  box-sizing: border-box;
  display: inline-block;
  margin: 30px 0;
}

/* Login button */
.inputContainer button[type="submit"] {
  flex: 1;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  margin: 30px 0;
  box-sizing: border-box;
  display: inline-block;
}

/* "Forgot Your Password?" link */
.forgotPassword a {
  color: #007bff;
  text-decoration: none;
}

/* "Existing user? Sign In" paragraph */
.existingUser {
  margin-top: 10px;
}

.existingUser a {
  color: #007bff;
  text-decoration: none;
}
