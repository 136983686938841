.list-group {
  margin-top: 30px;
  border-radius: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.list-group-item {
  font-size: 1.2rem;
  border-radius: 0;
  border: none;
}

.list-group-item:hover {
  background-color: #f2f2f2;
}

.bookHeader h1 {
  margin-top: 0;
}

.tableContainer {
  margin: 20px auto;
  max-width: 800px;
  position: relative; /* Add this to establish positioning context */
  z-index: 2; /* Add this to set the stacking order */
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.table th,
.table td {
  padding: 12px 15px;
  text-align: left;
  vertical-align: middle;
}

.table thead th {
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
}

.tableContainer {
  overflow: auto;
}

.table tbody tr:nth-of-type(even) {
  background-color: #f7f7f7;
}

.bookImg {
  background-image: url('../../../images/pexels-ksenia-chernaya-3952073.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  position: relative; /* Add this to establish positioning context */
  z-index: 1; /* Add this to set the stacking order */
}

/* Centered Pagination */
.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  gap: 10px; /* Add space between buttons and text */
}

.paginationContainer button {
  padding: 10px 20px;
  background-color: #04AA6D;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.paginationContainer button:hover {
  background-color: #45a049;
}

/* Page Number Styles */
.paginationContainer span {
  font-size: 16px;
  padding: 10px;
}

/* button[type=submit] {
  width: 25%;
  background-color: #04AA6D;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 40px;
}

.pagination-container button:hover {
  background-color: #45a049;
} */

.pagination-info {
  font-size: 16px;
}

/* //REVIEW LATER */
.table tbody tr:nth-of-type(odd) {
  background-color: #ffffff; /* White background for odd rows */
}

.table tbody tr:nth-of-type(even) {
  background-color: #f7f7f7; /* Light gray background for even rows */
}

/* Add hover effect */
.table tbody tr:hover {
  background-color: #e9e9e9; /* Slightly darker background on hover */
}