/* Styles */

.searchImg {
    /* background-image: url('../../../images/library-dublin.jpg'); */
    background-image: url('../../../images/pexels-ksenia-chernaya-3952073.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
} 

/* Add a background color to the submit button on mouse-over */
.button[type=submit]:hover {
background-color: #45a049;
}

/* Center the search bar and enlarge its size */
.autocompleteContainer {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px; /* Adjust the width as needed */
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #04AA6D; */
    background-color: transparent;
    border-radius: 4px; /* Add border radius */
  }

/* Keep the search bar at a fixed width */
.autocompleteContainer input[type="text"] {
    width: 100%; /* Occupy all available width within the container */
    max-width: 300px; /* Set a maximum width to prevent excessive stretching */
} 

/* Styling for the autocomplete pop-up */
.autocompletePopup {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    color: #333; /* Add text color for better visibility */
    padding: 8px; /* Add padding to make the results more readable */
  }
  
  .autocompletePopup a {
    text-decoration: none; /* Remove underlines from links */
    color: #333; /* Add text color for links */
  }
  
  .autocompletePopup a:hover {
    background-color: #f0f0f0;
  }