/* Styles */

.text-count {
    font-size: 60px;
    font-weight: bold;
    color: white;
    position: fixed; /* fixed */
    top: 75%;
    left: 35%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    padding: 10px 20px; /* Add some padding to the text */
    border-radius: 10px; /* Rounded corners for the background */
    backdrop-filter: blur(5px); /* Background blur effect */
    z-index: 1; /* Ensure the text is above the blurred background */
  }

.library-img-background {
    background-image: url('../../../images/library_white.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
} 

@media (max-width: 768px) {
  .text-count {
    font-size: 10px; /* Adjust font size for smaller screens */
    top: 90%; /* Adjust the vertical position */
    padding: 5px 10px; /* Adjust padding for smaller screens */
    left: 70%;
    transform: none;
  }
}